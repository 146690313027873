import Header from './header'
import Footer from './footer'
import Head from 'next/head'
import Popups from "../../popups";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import styles from './index.module.scss'
import TopSections from "./topSections";
import MobileMenu from "./mobileMenu";
import React, {useEffect, useMemo, useState} from "react";
import {getUser} from "/redux/action-creaters/user";
import {getBacket} from "/redux/action-creaters/cart";
import {useRouter} from "next/router";
import FeedBack from '~/components/common/feedBack';
import {getEdostMinDays} from "/redux/action-creaters/location";
import {fetchContacts} from "/redux/action-creaters/contacts"
import { getDeliveryCityData } from '@/redux/action-creaters/location';
import {fetchSections} from "/redux/action-creaters/sections";

const mskScript = `
   <script src="https://myreviews.dev/widget/dist/index.js" defer></script>
    <script defer>
        (function (){
          var ru = "ru";
          var myReviewsInit = function () {
            new window.myReviews.VerticalWidget({
              uuid: "2b72f2e6-13c9-4899-ad0e-1688a5dda886",
              name: "g94941526",
              additionalFrame: "button",
              variant: "default",
              lang:"ru",
              widgetId: "1"
            }).init();
          };
        if (document.readyState === "loading") {
          document.addEventListener('DOMContentLoaded', function () {
              myReviewsInit()
          })
        } else {
          myReviewsInit()
        }
        })()
    </script>
  `

const spbScript = `
    <script src="https://myreviews.dev/widget/dist/index.js" defer></script>
    <script defer>
        (function (){
          var ru = "ru";
          var myReviewsInit = function () {
            new window.myReviews.VerticalWidget({
              uuid: "71be0eaa-22da-473a-8758-f236bd4ccd15",
              name: "g59447692",
              additionalFrame: "button",
              variant: "default",
              lang:"ru",
              widgetId: "1"
            }).init();
          };
        if (document.readyState === "loading") {
          document.addEventListener('DOMContentLoaded', function () {
              myReviewsInit()
          })
        } else {
          myReviewsInit()
        }
        })()
    </script>
  `
import BackForm from "../../common/backForm";

export default function Layout({ children, scripts, isIndex = true, host = '' }) {
    const router = useRouter();
    const dispatch = useDispatch();
    const {isShowTopSections} = useSelector(state => state.pageState)
    const {isShowMobileMenu} = useSelector(state => state.pageState)
    const {title, description, yandexTag, googleTag} = useSelector(state => state.meta)
    const {
        cityName,
        deliveyCityData,
        deliveyCityDataLoaded
    } = useSelector(state => state.location)
    const {contacts, contactsData} = useSelector(state => state.contacts)

    const deliveryCity = useMemo(() => {
        if (deliveyCityData?.region === 'Ленинградская' || deliveyCityData?.region === 'Санкт-Петербург') {
            return 'Санкт-Петербург';
        }
        if (deliveyCityData?.region === 'Московская' || deliveyCityData.region === 'Москва') {
            return 'Москва';
        }
        return cityName;
    }, [cityName, deliveyCityData]);

    useEffect(() => {
        let contactsData ={
            SHOPS: []
        }

        if (contacts.length > 0) {
            if (deliveyCityDataLoaded && deliveryCity) {
                contactsData = contacts.find(({NAME}) => NAME === deliveryCity);
                if (!contactsData) {
                    contactsData = contacts.find(({NAME}) => NAME === 'Центральный');
                }
            } else {
                dispatch(getDeliveryCityData())
            }
        } else {
            dispatch(fetchContacts())
        }

        dispatch({type: 'SET_CONTACTS_DATA', payload: contactsData})
    }, [contacts, deliveyCityDataLoaded, deliveryCity])

    useEffect(() => {
        if (contactsData.NAME === 'Центральный' && deliveyCityDataLoaded) {
            dispatch(getEdostMinDays(deliveryCity, deliveyCityData?.kladr_id));
        }
    }, [contactsData, deliveryCity, deliveyCityDataLoaded]);

    useEffect(() => {
        dispatch(getUser(true))
    }, []);

    const {user} = useSelector(state => state.user)

    useEffect(() => {
        if (user.IS_MANAGER) {
            const links = document.getElementsByTagName('a')
            for (const link of links) {
                if (!link.href.includes('utm_source=sales_team')) {
                    link.href = link.href + '?utm_source=sales_team'
                }
            }

            if (history.pushState) {
                var baseUrl = "https://" + window.location.host + window.location.pathname;
                var newUrl = baseUrl + '?utm_source=sales_team';
                history.pushState(null, null, newUrl);
            }
        }
    })

    useEffect(() => {
        dispatch(getBacket(true))
    }, []);


    const {sections} = useSelector(state => state.sections)
    useEffect(() => {
        if (sections.length === 0) {
            dispatch(fetchSections())
        }
    }, [sections]);

    const {basket} = useSelector(state => state.cart)

    const {detail} = useSelector(state => state.news)

    const canonical = useMemo(() => {
        if (router.asPath.indexOf('/catalog/farkopy/filter/marka-lada/') > -1) {
            return (router.asPath.split('/kuzov-')[0] + '/').replace('//', '/')
        }
        if (router.asPath.indexOf('/catalog/') > -1) {
            return (router.asPath.split('?')[0] + '/').replace('//', '/')
        }
        if (router.asPath.indexOf('/articles/') > -1) {
            return 'https://farkop.ru' + (router.asPath.split('?')[0] + '/').replace('//', '/')
        }
        if (router.asPath.indexOf('/obzory-na-tovary/') > -1) {
            return 'https://farkop.ru' + (router.asPath.split('?')[0] + '/').replace('//', '/')
        }
        if (router.asPath.indexOf('/news/') > -1) {
            return 'https://farkop.ru' + (router.asPath.split('?')[0] + '/').replace('//', '/')
        }
        if (router.asPath.indexOf('/nashe-portfolio/') > -1) {
            let dd = `farkop`
            if (detail.PROPERTY_DOMAIN_VALUE && detail.PROPERTY_DOMAIN_VALUE !== 'farkop') {
                dd = `${detail.PROPERTY_DOMAIN_VALUE}.farkop`;
            }
            return `https://${dd}.ru${(router.asPath.split('?')[0] + '/').replace('//', '/')}`
        }
        if (router.asPath.indexOf('/brands/') > -1) {
            return (router.asPath.split('?')[0] + '/').replace('//', '/')
        }
        if (router.asPath.indexOf('/shops/28961/') > -1) {
            return 'https://spb.farkop.ru/shops/28961/';
        }
        if (router.asPath.indexOf('/shops/28962/') > -1) {
            return 'https://spb.farkop.ru/shops/28962/';
        }
        if (router.asPath.indexOf('/shops/115374/') > -1) {
            return 'https://msk.farkop.ru/shops/115374/';
        }
        if (router.asPath.indexOf('/shops/121659/') > -1) {
            return 'https://msk.farkop.ru/shops/121659/';
        }
        if (router.asPath.indexOf('/shops/130215/') > -1) {
            return 'https://nn.farkop.ru/shops/130215/';
        }
        return false
    }, [router])

    useEffect(() => {
        if (typeof ym !== "undefined") {
            ym(20518312, 'hit', router.asPath)
        }
        if (router.asPath.indexOf('/catalog/farkopy/') > -1) {
            (function (d, w) {

                var n = d.getElementsByTagName("script")[0],
        
                    s = d.createElement("script");
        
                    s.type = "text/javascript";
        
                    s.async = true;
        
                    s.src = "https://victorycorp.ru/index.php?ref="+d.referrer+"&page=" + encodeURIComponent(w.location.href);
        
                    n.parentNode.insertBefore(s, n);
        
            })(document, window);
        }
    }, [router]);

    const [scrollTop, setScrollTop] = useState(0);

    const isScrolled = useMemo(() => {
        if (typeof window === "undefined") {
            return false
        }
        return ((window.innerWidth > 991 && scrollTop > 45) || scrollTop > 30 || isShowMobileMenu)
    }, [scrollTop])

    useEffect(() => {
        const onScroll = e => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const {product} = useSelector(state => state.product)

    const productImage = useMemo(() => {
        return product?.PHOTOS?.[0]?.[1];
    }, [product])

    const itemListElement = [
        {
            "name": "Фаркопы",
            "description": "Каталог фаркопов с гарантией производителя",
            "item": `https://${host}/catalog/farkopy/`
        }
    ]

    if (host === 'msk.farkop.ru' || host === 'msk.farkop-msk.ru') {
        itemListElement.push({
            "name": "Установка фаркопов",
            "description": "Установка фаркопов с гарантией в Москве",
            "item": `https://${host}/services/ustanovka-farkopa-v-moskve/`,
        }, {
            "name": "Регистрация фаркопов",
            "description": "Официальная регистрация фаркопа в Москве",
            "item": `https://${host}/services/vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_moskve/`,
        })
    }

    if (host === 'spb.farkop.ru' || host === 'spb.farkop-msk.ru') {
        itemListElement.push({
            "name": "Установка фаркопов",
            "description": "Установка фаркопов с гарантией в Санкт-Петербурге",
            "item": `https://${host}/services/ustanovka-farkopa/`,
        }, {
            "name": "Регистрация фаркопов",
            "description": "Официальная регистрация фаркопа в Санкт-Петербурге",
            "item": `https://${host}/services/vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_sankt-peterburge/`,
        })
    }

    itemListElement.push({
        "name": "Магазины",
        "description": "Адреса магазинов и устоновочных центров",
        "item": `https://${host}/shops/`,
    },{
        "name": "Полезная информация",
        "description": "Полезные статьи о фаркопах, написаны экспертами",
        "item": `https://${host}/articles/`,
    },{
        "name": "Контакты",
        "description": "Контактная информация магазина Фаркоп.ру",
        "item": `https://${host}/contacts/`,
    })

    const tags = {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": itemListElement.map((item, index) => {
            return {
                "@type": "ListItem",
                "position": index + 1,
                ...item,
            }
        })
    }

    return (
        <>
            <Head>
                <link rel="preload" as="image/svg+xml" href="icons.svg"/>
                {title && <title>{title}</title>}
                {description && <meta
                    name="description"
                    content={description}
                    key="desc"
                />}
                <link rel="shortcut icon" href="/favicon.ico" type="image/png"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"></meta>
                <meta name="viewport" content="width=device-width, user-scalable=no"/>
                <meta name="robots" content={isIndex ? 'index, follow' : 'noindex'}/>
                {title && <meta property="og:title" content={title}/>}
                <meta property="og:type" content="article"/>
                <meta property="og:url" content={canonical || router.asPath}/>
                <meta property="og:image" content={productImage || '/logo2.svg'}/>
                {yandexTag && (
                    <meta name="yandex-verification" content={yandexTag}></meta>
                )}
                {googleTag && (
                    <meta name="google-site-verification" content={googleTag}></meta>
                )}
                {canonical && (
                    <link rel="canonical" href={canonical}></link>
                )}
                {host === 'farkop.ru' && (
                    <meta name="msvalidate.01" content="2A3993BC6CD41DA6496A729BCB0E39C1"></meta>
                )}
                {host === 'farkop.ru' && (
                    <div dangerouslySetInnerHTML={{__html: `
                    <!-- Top.Mail.Ru counter -->
                        <script type="text/javascript">
                        var _tmr = window._tmr || (window._tmr = []);
                        _tmr.push({id: "3546707", type: "pageView", start: (new Date()).getTime(), pid: "USER_ID"});
                        (function (d, w, id) {
                          if (d.getElementById(id)) return;
                          var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                          ts.src = "https://top-fwz1.mail.ru/js/code.js";
                          var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                          if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
                        })(document, window, "tmr-code");
                        </script>
                        <noscript><div><img src="https://top-fwz1.mail.ru/counter?id=3546707;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div></noscript>
                        <!-- /Top.Mail.Ru counter -->
                    `}}></div>
                )}
                <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(tags)}}>
                </script>
            </Head>
            <Header
                contactsData={contactsData}
                user={user}
                basket={basket}
                isScrolled={isScrolled}
            />
            <div className={clsx((isShowTopSections || isShowMobileMenu) && styles.Hidden)}>
                {children}
                <Footer host={host}/>
            </div>
            {isShowTopSections && <TopSections/>}
            {isShowMobileMenu && <MobileMenu user={user} contactsData={contactsData}/>}
            {!isShowTopSections && !isShowMobileMenu && (
                <>
                    <FeedBack isScrolled={isScrolled}/>
                    <Popups/>
                    <BackForm basket={basket} contactsData={contactsData}/>
                </>
            )}
            <div dangerouslySetInnerHTML={{__html: scripts}}/>
            {cityName === 'Москва' && (
                <div dangerouslySetInnerHTML={{__html: mskScript}}/>
            )}
            {cityName === 'Санкт-Петербург' && (
                <div dangerouslySetInnerHTML={{__html: spbScript}}/>
            )}
        </>
    )
}

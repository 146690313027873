export const setShowTopSections = (isShow) => {
    return async (dispatch) => {
        dispatch({type: 'SET_SHOW_TOP_SECTIONS', payload: isShow})
    }
}

export const setShowMobileMenu = (isShow) => {
    return async (dispatch) => {
        dispatch({type: 'SET_SHOW_MOBILE_MENU', payload: isShow})
    }
}
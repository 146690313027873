import styles from "./index.module.scss";
import Icon from "/components/common/icon";
import {popupTypes} from "/redux/reducers/popupReducer";
import {showPopup} from "/redux/action-creaters/popup";
import {useDispatch, useSelector} from "react-redux";
import {useMemo, useState} from "react";
import {useRouter} from "next/router";

export default function BackForm({basket, contactsData}) {
    const dispatch = useDispatch();
    const {visibleType} = useSelector(state => state.popup)
    const [isShow, setIsShow] = useState(true)

    const phone = useMemo(() => {
        setTimeout(() => {
            if (typeof window === 'object') {
                window?.ct_replace_495 && window?.ct_replace_495()
                window?.ct_replace_812 && window?.ct_replace_812()
                window?.ct_replace_800 && window?.ct_replace_800()
            }
        }, 500);
        return contactsData?.PHONE
    }, [contactsData])

    const items = [
        {
            name: 'Заказать звонок',
            icon: 'backformcall',
            popupCode: popupTypes.backCall,
            mobileLink: `tel:${phone}`
        },
        {
            name: 'Telegram',
            icon: 'tg',
            link: 'https://telegram.me/farkop_rubot',
        },
        {
            name: 'WhatsApp',
            icon: 'wp',
            link: 'https://wa.me/+79811779326',
        },
        /*{
            name: 'Видеоконсультация',
            icon: 'backformvideo',
            popupCode: popupTypes.videoConsultation,
        },*/
        {
            name: 'Написать нам',
            icon: 'vk',
            link: 'https://vk.com/farkopru',
        },
        /*{
            name: 'Похвалить',
            icon: 'backformlike',
            popupCode: popupTypes.like,
        },
        {
            name: 'Пожаловаться',
            icon: 'backformdislike',
            popupCode: popupTypes.dislike,
        }*/
    ]

    const router = useRouter();
    const cnt = useMemo(() => {
        return basket ? basket.QTY : 0;
    }, [basket])

    return visibleType === popupTypes.none && (
        <div className={styles.BackForm}>
            {isShow ? (
                <div className={styles.Close} onClick={() => setIsShow(false)}>
                    <Icon id={'backformclose'} size={22} fill={'#000'}/>
                </div>
            ) : (
                <div className={styles.Show} onClick={() => setIsShow(true)}>
                    <Icon id={'backformquestion'} size={22}/>
                </div>
            )}

            {isShow && (
                <div className={styles.Items}>
                    {items.map(item => (
                        <div key={item.icon} className={styles.Item} onClick={() => {
                            if (window.innerWidth < 991 && item.mobileLink) {
                                window.open(item.mobileLink);
                            } else if (item.link) {
                                window.open(item.link, "_blank");
                            } else {
                                dispatch(showPopup(item.popupCode))
                            }
                        }}>
                            <div>
                                <span>{item.name}</span>
                                <div className={styles.Icon}>
                                    <Icon id={item.icon} size={22}/>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div
                        className={styles.Cart}
                        onClick={() => {
                            router.push('/personal/cart/')
                        }}
                    >
                        <div className={styles.Icon}>
                            <Icon id='cart' width={22} height={22} stroke={'#2f69ff'} strokeWidth={2}/>
                            {cnt > 0 && (<span>{cnt}</span>)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
